import React from 'react'
import { List, Alert ,Image} from 'antd';
import junqi_ver from "./images/junqi_ver.png"

const Download = () => {


  const data = [
    <li><Alert message="注意：辅助适配的四国军棋为当前最新版，QQ游戏- 四国军棋角色版 2.1 Beta08 Build033 ，核对版本无误后再下载辅助" type="warning" showIcon />
    <Image src={junqi_ver} preview={false} />
    </li>,
    <li>下载地址1: <a href="https://wwi.lanzoup.com/i0KRA221fzde" target="_blank" rel="noreferrer">https://wwi.lanzoup.com/i0KRA221fzde</a></li>,
    <li>下载地址2: <a href="https://pan.baidu.com/s/16xUIo8ovxeDKeMidM4qimg?pwd=8888" target="_blank" rel="noreferrer">https://pan.baidu.com/s/16xUIo8ovxeDKeMidM4qimg?pwd=8888</a>提取码: 8888</li>,
    <li>下载地址3: QQ群共享：140386627 <a target="_blank" rel="noreferrer" href="https://qm.qq.com/cgi-bin/qm/qr?k=DfCNuWw8qLdS26jlZ8JI8zA6AdwIe4_H&jump_from=webapi&authKey=yzWHi31IQIFBbZxN05+7Gjvv1UynxlcR3JJX31e36ERZV3eKNO1Tlp8oyTHKZkBV">
      <img border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="QQ四国军棋助手" title="QQ四国军棋助手" />
    </a></li>,
  ];



  return (
    <>
      <List
        size="large"
        header={<div>QQ四国军棋辅助下载地址：(仅Windows电脑版，无其它任何平台版本）</div>}
        footer={<div><Alert message="如果发现链接失效，请及时反馈给我们。" type="info" showIcon />
        </div>}
        bordered
        dataSource={data}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />


    </>
  )
}

export default Download