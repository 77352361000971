import React, { useState, useEffect } from 'react'
import { Card, Col, Row } from 'antd';
import { Table } from 'antd';

import axios from 'axios'


const Rank = () => {

    const columns = [
        {
            title: '排行',
            dataIndex: 'rank',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'QQ号码',
            dataIndex: 'qq',
            key: 'qq',
        },
        {
            title: '贡献数',
            dataIndex: 'cnt',
            key: 'cnt',
        },
    ]
    const [weekList, setWeekList] = useState([]);
    const [monthList,setMonthList] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const [response1, response2] = await Promise.all([
              axios.get(`/api/junqi/week_contribution_rank`),
              axios.get(`/api/junqi/month_contribution_rank`)
            ]);
            setWeekList(response1.data.list);
            setMonthList(response2.data.list);
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchData();
      }, []);

    return (
        <Row justify="start">
            <Col span={6}>
                <Card
                    size="small"
                    title="本周贡献排行前10"
                    style={{
                        width: 300,
                    }}
                >
                    <Table
                        size="small"
                        columns={columns}
                        dataSource={weekList}
                        pagination={{
                            position: ["none", "none"],
                        }}
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card
                    size="small"
                    title="本月贡献排行前10"
                    style={{
                        width: 300,
                    }}
                >
                    <Table
                        size="small"
                        columns={columns}
                        dataSource={monthList}
                        pagination={{
                            position: ["none", "none"],
                        }}
                    />
                </Card>
            </Col>
        </Row>

    )
}

export default Rank