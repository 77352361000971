import React from 'react'
import { Alert,Image } from 'antd';
import example from "./images/example.png"

const Intro = () => {
    return (
        <>
            <p>
                QQ四国军棋云助手（原QQ四国军棋助手）又叫QQ四国军棋云参谋，它是一款QQ四国军棋游戏辅助软件（不是什么刷分器，我不推荐大家去刷分，分数刷上去了，实际水平没到那个水平有什么用），它由QQ四国军棋助手升级而来。数据库由原来的本地改为采用网络存储，只要在任何一台电脑下载打开软件，都可以使用。
            </p>
            <p>
                它能自动帮你记录下每一盘与你对局过的网友的布局，也就是其它3个玩家（上家，下家，对家）的布局，并存于云数据库中，同时所有会员的数据是共享的。
            </p>
            <p>
                如果第二次与相同玩家的在同一局棋局中，则自动将该玩家的历史布局调出，并自动标记在棋子上（见最后的附图显示的效果图）。
            </p>
            <p>
                如果你要下棋的痛快厮杀的快感，它就能给你奇爽无比的感觉！！
            </p>
            <p>
                如果你想要通过观察标记出来的棋子与你实际判断的差异进而提升自己的水平，那么它也是比较好的辅助工具！！！
            </p>
            <p>
                我也是一位QQ四国军棋爱好者。深刻体会到下四国军棋到一定阶段，水平会一直难以提高（对应的积分也无法上去），俗话说的遇到了瓶颈。
                这个时候如果使用我自主开发的这款QQ四国军棋云参谋，则它可以在很大程度上帮到你。被标记玩家的一举一动，你都了如指掌。比如伪装，拿了个工兵想充当炸弹，军长或者司令潜伏不动，等等。你可以看到该玩家对应的一些行为。会察觉到一些不同。
            </p>
            <p>
                <Alert
                    message="注意: 这工具仅是辅助，不是外挂，也不是透视！腾讯QQ四国军棋棋子大小在服务器端进行验证，这个机制决定了不可能存在透视！纯粹找QQ四国军棋透视的同学可以死心了。"
                    description=""
                    type="warning"
                    showIcon
                />
            </p>
            <p>
            <Image width={ 600 } src={example} preview={false} />
            </p>
        </>
    )
}

export default Intro