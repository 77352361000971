import React from 'react'
import { Image } from 'antd';
import img from './images/unzip.png';
import { Alert } from 'antd';

const Install = () => {
    return (
        <>
            <p>
                <Alert
                    message="注意："
                    description={<>1） 下载后为RAR或者ZIP格式的压缩包。请在压缩包文件上右键，选择解压缩，将压缩包里面的文件解压缩到一个目录下。<br/>
                        2） 请解压后再使用工具，不要直接双击查看压缩包并在压缩包内双击打开工具！</>}
                    type="warning"
                    showIcon

                />
            </p>
            <p>
                <Image src={img} preview={false} />
            </p>
            <p>

                <Alert
                    message={<>提示解压缩密码时，请输入密码<b>123</b></>}
                    description=""
                    type="warning"
                    showIcon

                />
            </p>
            <p>
                <Alert
                    message="之所以添加解压密码是为了防止工具下载后直接被部分杀毒软件隔离删除。"
                    description=""
                    type="info"
                    showIcon

                />
            </p>
            <p>
            <Alert
                    message={ <>如果你的电脑没装解压缩软件，建议你可以下载
                        <a href="https://www.7-zip.org/" target="_blank" rel="noreferrer">7-ZIP</a> 或者
                        <a href="https://www.bandisoft.com/bandizip/" target="_blank" rel="noreferrer">BendiZip</a> 解压缩软件。
                        强烈不建议你下载使用国内的一些流氓公司制作的解压缩软件！
                    </>}
                    description=""
                    type="info"
                    showIcon

                />
                
            </p>
            <p>
            <Alert
                    message={ <>解压后运行文件夹内的EXE文件，<br/> 1) 如果无法运行，提示需要安装.Net 框架的话，则可下载
                        <a href="https://wwi.lanzoup.com/iXhnp049gkje" target="_blank" rel="noreferrer"> DotNet4.0</a> 或者
                        <a href="https://wwi.lanzoup.com/iHwM70da7oje" target="_blank" rel="noreferrer"> DotNet4.15</a> （二者任选其一安装即可） <br/>
                        2) 如果可以正常运行，则请跳到下一步进行充值操作。
                    </>}
                    description=""
                    type="info"
                    showIcon

                />
                
            </p>
        </>
    )
}

export default Install