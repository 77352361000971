import { PageHeader } from 'antd';
import MyTabs from './MyTabs';
import { Col, Row } from 'antd';

import 'antd/dist/antd.min.css';
import { QqOutlined } from '@ant-design/icons';

function App() {
    return (
        <div className="App">

            <Row justify="center">
                <Col span={4}></Col>
                <Col span={6}>
                    <PageHeader
                        backIcon={<QqOutlined style={{ fontSize: '28px', color: '#08c' }} />}
                        className="site-page-header"
                        onBack={() => null}
                        title="小A"
                        subTitle="QQ四国军棋云参谋"
                    />
                </Col>
                <Col span={8}>
                <PageHeader
                        backIcon={""}
                        className="site-page-header"
                        onBack={() => null}
                        title="本站域名： jq.xiagegou.com (军棋 下个够)"
                        subTitle=""
                    />
                </Col>
                <Col span={6}>
                    <a target="_blank" rel="noreferrer" href="https://qm.qq.com/cgi-bin/qm/qr?k=DfCNuWw8qLdS26jlZ8JI8zA6AdwIe4_H&jump_from=webapi&authKey=yzWHi31IQIFBbZxN05+7Gjvv1UynxlcR3JJX31e36ERZV3eKNO1Tlp8oyTHKZkBV">
                        <img border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="QQ四国军棋助手" title="QQ四国军棋助手" />
                    </a>
                </Col>
            </Row>
            <Row justify="center">
                <Col span={2}></Col>
                <Col span={20}>
                    <MyTabs />
                </Col>
                <Col span={2}></Col>
            </Row>

        </div>
    );
}

export default App;
