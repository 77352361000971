import { Tabs } from 'antd';

import React from 'react'
import Charge from './Charge';
import Download from './Download';
import FAQ from './FAQ';
import Install from './Install';
import Intro from './Intro';
import Support from './Support';
import Rank from './Rank';

const MyTabs = () => {
    const items = [
        { label: '0.简要介绍', key: 'intro', children: <Intro /> }, // 务必填写 key
        { label: '1.下载地址', key: 'item-2', children: <Download /> },
        { label: '2.解压安装', key: 'item-3', children: <Install /> },
        { label: '3.自助充值', key: 'item-4', children: <Charge /> },
        { label: '4.常见问题', key: 'item-5', children: <FAQ /> },
        { label: '5.远程协助', key: 'item-6', children: <Support /> },
        { label: '6.贡献排行', key: 'item-7', children: <Rank /> },
      ];
    return <Tabs 
        items={items}
        type="card"
        size={ "large"}
        tabPosition="left"
        />;
}

export default MyTabs