import React from 'react'
import { Collapse } from 'antd';
const { Panel } = Collapse;


const FAQ = () => {
  return (
    <>
    <Collapse defaultActiveKey={['1','2','3','4','5','6','7']}>
    <Panel header={<b>1、问：为什么标记的“不准”？</b>} key="1">
        <p>答：标记基于玩家曾经使用过的布局，如果他们更换了布局，当然标记出来的与他们当前使用的布局就有差异了。当前这一局下完后辅助会再次记录他们最新的布局。</p>
      </Panel>
      <Panel header={<b>2、问：这个软件是外挂吗，安全吗？</b>} key="2">
        <p>答：不是的。它不能刷分，它只是QQ四国军棋辅助工具。本软件只是卖家作为一个QQ四国军棋爱好者，加上业余爱好编程，经过摸黑几天几夜后续打磨完成的作品。
            360之类的“杀毒”软件报病毒纯属误报。自行添加为信任即可。我要有心思去制作病毒的话，我还不如多下2盘棋呢~ 不信任则不用即可~</p>
      </Panel>
      <Panel header={<b>3、问：防作弊房间也能用吗？评测房间呢？</b>} key="3">
        <p>答：是的。都能用。稍有差别的，实际使用中防作弊房间是最佳的。评测房间中碰到相同玩家的机率稍小一些，因为评测的原因，系统会安排与你当前所评测的水平相当（级别相近或者相同）的玩家。
          而本辅助是记录和你曾经玩过的玩家。输你赢你的人级别就可能变化了比你低或者高了。再次分配给你的机率稍微低了一些。

        </p>
      </Panel>
      <Panel header={<b>4、问：哪有这么容易碰上相同玩家呢？</b>} key="4">
        <p>答：当前数据库中的布局库是所有会员共享的。目前会员积累的布局数量已经相当可观。
            另外建议选择合适的房间。比如像人相对较满的防作弊房间，里面的玩家基本上下完一局马上开始下一局。这里只要你也跟上相同的节奏，碰上的概率还是相当大的。</p>
      </Panel>
      <Panel header={<b>5、问：这工具属于透视器吗？</b>} key="5">
        <p>答：不是。腾讯QQ游戏四国军棋的通讯原理，棋子大小的验证等都在服务器端进行的验证。因此不存在所谓的透视器。
            请不要相信市面上存在的所谓的透视器。那多半是个木马或者其它推广软件。请大家不要上当。
            弄不好电脑还给中了木马或者被安装乱七八糟的软件，得不偿失！</p>
      </Panel>
      <Panel header={<b>6、换台电脑还能使用吗？</b>} key="6">
        <p>答：可以的。只要保存好我们的工具。你在任何电脑只要下载安装了该工具，就可以使用。所有数据保存在云端服务器，不会因为换电脑而丢失。本工具在会员群的群共享也有。
</p>
      </Panel>
      <Panel header={<b>7、为什么我下载后提示是病毒？</b>} key="7">
        <p>答：关于部分用户的360或者金山杀毒软件误报风险软件：用过电脑的人都知道360的报险方式，不是大牌的软件都叫风险软件！
            我们的软件是自己原创，不是盗版，销售的是软件，还来这里放毒，有这个必要吗？
            再说：三6奶粉更是经过了ISOXXX认证了，它就安全了吗？所以可以让杀毒软件放行并加为信任即可。
            有时可以先建一个文件夹，然后加为白名单后，再把下载的软件放在里面进行解压。如果操作还有困难，可联系我们远程帮忙解决！</p>
      </Panel>
    </Collapse>
    </>
  )
}

export default FAQ