import React from 'react'
import img from "./images/oray.png"
import { Image } from "antd"

const Support = () => {
    return (
        <>
            <p>
                如果您有问题需要远程协助帮忙查看的话：
            </p>
            <p>
                推荐下载安装向日葵软件，然后告诉我葵码和验证码，以便我远程协助处理您的问题。
            </p>
            <p>
                下载地址：<a href="https://sunlogin.oray.com/download" target="_blank" rel="noreferrer"> https://sunlogin.oray.com/download</a>
            </p>
            <p>
                访问上方链接后，如果你不想安装完整版，可以下载下面第2个简约版，根据您的系统情况选择64位或者32位的程序下载后进行免安装绿色运行。
                如果不清楚自己系统是64位还是32位的，那么请直接下载32位的即可。
            </p>
            <p>
                <Image src={img} preview={false} />
            </p>
            <p>
                运行后，会有葵码和验证码，请复制发给我。（如果你是淘宝找到我们的，可以淘宝旺旺发消息给我）
            </p>
        </>
    )
}

export default Support